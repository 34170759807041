.filters {
  padding: 54px 15px 20px;
  background-color: #FFF;
  height: 100vh;
  width: 100%;
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  z-index: 99;

  @media (min-width: 768px) {
    width: 320px;
    box-shadow: 0 0 10px #808080;
  }

  @media (min-width: 1025px) {
    padding-right: 10px;
    padding-left: 0;
    margin-right: 30px;
    width: 290px;
    height: auto;
    position: relative;
    display: block;
    box-shadow: unset;
  }

  &__close {
    position: absolute;
    right: 15px;
    top: 20px;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    background: unset;
    cursor: pointer;

    @media (min-width: 1025px) {
      display: none;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: calc(100% - 125px);
    overflow-y: scroll;
    position: relative;

    &_level-2 {
      display: none;
      margin-top: 20px;
      overflow-y: initial;

      &::after {
        display: none;
      }

      @media (min-width: 1025px) {
        background-color: #f9f9f9;
      }
    }

    &_level-1 {
      overflow: hidden;
      height: 100%;

      &::after {
        display: none;
      }
    }

    @media (min-width: 768px) {
      max-height: calc(100% - 100px);

      &::after {
        content: '';
        position: fixed;
        left: 0;
        bottom: 116px;
        width: 290px;
        height: 17px;
        box-shadow: 0 0 17px 12px #FFF;
        background-color: #FFF;
      }
    }

    @media (min-width: 1025px) {
      overflow: hidden;
      max-height: 100%;

      &::after {
        display: none;
      }
    }
  }

  &__item-name {
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    color: #666;
    padding: 7px 7px 7px 20px;
    position: relative;
    cursor: pointer;

    &::after, &::before {
      content: '';
      position: absolute;
      background-color: #666;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      transition: all .3s ease-in-out;
      width: 3px;
      height: 11px;
      left: 4px;
    }

    &:before {
      width: 11px;
      height: 3px;
      left: 0;
    }

    &_level-2 {
      font-size: 16px;
      padding: 4px 4px 4px 32px;
      color: #b3b3b3;
      display: block;
      line-height: 100%;
      text-transform: initial;
      border-bottom: 0;
      cursor: default;
      transition: all .3s ease-in-out;
      margin: 0 5px;

      &:before, &::after {
        display: none;
      }

      &:hover {
        background-color: #FFF;
      }
    }
  }

  &__item {
    position: relative;

    &_level-1 {
      padding-top: 15px;
      line-height: 100%;

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  &__item_selected > &__item-name_level-2 {
    color: #666;
  }

  &__item:not(:last-child) > &__item-name {
    border-bottom: 1px solid #E5E5E5;
  }

  &__item-name._opened {
    color: #D02D26;

    &:after {
      transition: all .3s ease-in-out;
      background-color: #D02D26;
      transform: translateY(-50%) rotate(90deg);
    }

    &:before {
      background-color: #D02D26;
      z-index: 2;
    }
  }

  &__item-label {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    font-size: 18px;
    text-transform: uppercase;
    color: #8c8c8c;

    &_level-1 {
      line-height: 100%;
      text-transform: initial;
      margin-left: 20px;
    }
  }

  &__item_level-2:not(:last-child) > &__item-label {
    border-bottom: 1px solid #f2f2f2;
  }

  &__item-button {
    background: unset;
    outline: 0;
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: inline-flex;
    color: #8c8c8c;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 15px;
      transition: all .3s ease-in-out;
      transform: rotate(0deg);
    }

    &._opened {
      color: #666;

      svg {
        transform: rotate(-180deg);
        transform-origin: center;
      }
    }
  }

  &__item-checkbox {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 19px;

    &_level-2 {
      top: 4px;
    }
  }
}
