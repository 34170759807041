.checkbox {
  width: 100%;
  height: 100%;
  display: inline-block;
  cursor: pointer;

  &__input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    height: 100%;
    width: 100%;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    transition: all .3s ease-in-out;

    &::after {
      content: "";
      position: absolute;
      visibility: hidden;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &__input:checked ~ &__checkmark:after {
    left: 4px;
    top: 2px;
    visibility: visible;
    width: 5px;
    height: 8px;
    border: solid #D02D26;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(405deg);
    -ms-transform: rotate(405deg);
    transform: rotate(405deg);
    transition: all .3s ease-in-out;
  }
}
