.button {
  border-radius: 30px;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &_red {
    background: #d02d26;
    &:hover {
      background: #bc150e;
    }
  }

  &_disabled {
    opacity: .45;
  }
}
