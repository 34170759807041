.input {
  border: none;
  border-bottom: 1px solid #E5E5E5;
  padding: 5px 20px;
  outline: none;
  width: 100%;
  font-size: 16px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }

  &:focus-visible {
    border: none;
    border-bottom: 1px solid #666;
  }

  &__box {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      margin-bottom: 15px;
    }
  }
}
