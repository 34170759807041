.inner-title {
  margin: 20px auto 8px auto;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  text-transform: uppercase;
  text-align: left;

  @media (min-width: 768px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }  

  &_center {
    text-align: center;

    @media (min-width: 1152px) {
      margin-top: 43px;
    }
  }

  &_rooms {
    text-align: center;
    margin-bottom: 20px;
    
    @media (min-width: 768px) {
      text-align: left;
    }
  }
}
