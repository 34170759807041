.catalog-page {
  padding-top: 83px;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    padding-top: 123px;
  }

  @media (min-width: 1025px) {
    display: flex;
  }

  @media (min-width: 1152px) {
    padding-top: 92px;
  }

  &__filter-btn {
    border-radius: 30px;
    display: flex;
    outline: 0;
    border: 0;
    cursor: pointer;
    transition: all .3s ease-in-out;
    align-items: center;

    &_show {
      margin: 0 auto 10px;
      background-color: #80B4B5;
      padding: 7px 43px;
      font-size: 12px;
      line-height: 16px;
      color: #FFF;
      text-transform: uppercase;

      @media (min-width: 768px) {
        padding: 12px 34px;
      }
    }

    &_apply {
      padding: 7px 14px;
      background-color: #80B4B5;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #FFF;
      height: 30px;

      &:hover {
        background-color: #3BA8AB;
      }
    }

    &_clear {
      padding: 7px 0 7px 10px;
      font-size: 13px;
      line-height: 13px;
      color: #b3b3b3;
      background-color: #f9f9f9;
      position: relative;
      height: 30px;
      width: 100px;
      border-radius: 25px;
      margin-left: 14px;

      svg {
        margin-right: 10px;
      }

      &:hover {
        background-color: #E5E5E5;
      }
    }
  }

  &__filter-action {
    display: flex;
    align-items: center;

    &_toggles {
      display: none;
      justify-content: flex-end;
      margin-bottom: 8px;

      @media (min-width: 1025px) {
        display: flex;
      }
    }

    &_show {
      @media (min-width: 1025px) {
        display: none;
      }
    }
  }

  &__content {
    margin-bottom: 10px;
    flex-grow: 1;

    @media (min-width: 768px) {
      margin-bottom: 40px;
    }
  }
}
