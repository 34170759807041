.feedback-popup {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.72);
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s 0s, opacity 0.25s 0.25s;

  &__wrapper {
    position: absolute;
    border-radius: 10px;
    left: calc(50% - 150px);
    padding: 30px 20px;
    top: 118px;
    background: #fff;
    width: 300px;
    height: 310px;
    transform: translate(100%, 0);
    transition: transform 0.5s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    @media (min-width: 768px) {
      width: 440px;
      left: calc(50% - 220px);
      padding: 30px 40px;
    }
  }

  &__checkmark {
    position: absolute;
    top: 20px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid #E5E5E5;

    :after {
      content: "";
      position: absolute;
      display: none;
    }

    :after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &__title {
    margin-top: 0;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  &__close{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 15px;
    height: 15px;
    background-image: url(../images/close.svg);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 10;
    border: none;
    background-color: #fff;
  }

  &__label {
    font-size: 12px;
    margin-left: 30px;
    margin-top: 20px;
    line-height: 15px;

    a {
      color: inherit;
    }
  }

  &__open {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.25s;
  }

  &__open .feedback-popup__wrapper {
    transform: translate(0, 0);
  }

  &__blocker {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: ' ';
    background: rgba(0,0,0,.5);
  }
}
