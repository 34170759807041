.form {
  &__input-col {
    max-width: 100%;

    &:first-child {
      margin-bottom: 10px;
    }

    @media (min-width: 768px) {
      max-width: 175px;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  &__button {
    width: 140px;
    height: 30px;
    padding: 7px 34px;
    font-size: 12px;
    line-height: 16px;
    margin: 20px auto 0;
    display: block;

    @media (min-width: 768px) {
      margin-top: 25px;
    }
  }
}
