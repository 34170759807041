.promo-block {
  background: #7f6d5b url(../images/bg_main.png) center bottom;
  background-size: cover;
  margin: 0 auto;

  @media (min-width: 768px) {
    background-image: url(../images/bg_main.png);
  }

  @media (min-width: 1152px) {
    background-image: url(../images/bg_main.png);
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      align-items: flex-start;
    }
  }

  &__logo {
    width: 143px;
    height: 140px;
    background: url(../images/logo-mobile.svg) no-repeat;
    margin-top: 73px;
    line-height: 0;
    font-size: 0;

    @media (min-width: 768px) {
      background: url(../images/logo.svg) no-repeat;
      background-size: 100% 100%;
      margin-top: 133px;
      height: 70px;
      width: 400px;
    }

    @media (min-width: 1152px) {
      margin-top: 155px;
      width: 459px;
      height: 78px;
    }
  }

  &__description {
    display: flex;
    max-width: 480px;
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
    text-align: center;
    margin-top: 10px;

    & p {
      margin: 0;
    }

    @media (min-width: 768px) {
      height: auto;
      width: 480px;
      margin-left: 78px;
      margin-top: 15px;
      text-align: left;
      line-height: 21px;
    }

    @media (min-width: 1152px) {
      margin-left: 92px;
      margin-top: 17px;
    }
  }

  &__button {
    width: 140px;
    height: 30px;
    margin: 30px 0 34px 0;
    padding: 7px 34px;
    font-size: 12px;
    line-height: 16px;

    @media (min-width: 768px) {
      margin-left: 77px;
      margin-top: 16px;
      font-size: 16px;
      line-height: 21px;
      padding: 12px 41px;
      width: 170px;
      height: 45px;
      margin-bottom: 60px;
    }

    @media (min-width: 1152px) {
      margin-left: 92px;
      margin-bottom: 137px;
      width: 140px;
      height: 30px;
      padding: 7px 37px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
