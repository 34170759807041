.equipment-card {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  background: #f8f8f8;
  border-radius: 10px;

  @media (min-width: 768px) {
    padding: 20px;
  }

  @media (min-width: 1024px) {
    padding: 30px;
  }

  &__logo {
    width: 45px;
    height: auto;

    @media (min-width: 768px) {
      width: 80px;
    }
  }

  &__info {
    display: flex;
    width: calc(100% - 45px);
    padding-left: 10px;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 768px) {
      width: calc(100% - 80px);
      padding-left: 20px;
    }

    @media (min-width: 1024px) {
      padding-left: 30px;
    }
  }

  &__title {
    margin: 0 0 10px;
    font-size: 18px;
    line-height: 18px;
    font-style: normal;
    font-weight: normal;
  }

  &__description {
    color: #868686;
    font-size: 12px;
    line-height: 12px;

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 14px;
    }
  }
}
