.card-nav {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 10px 40px 10px;

  @media (min-width: 768px) {
    padding: 40px 30px 40px 30px;
  }
  
  @media (min-width: 1152px) {
    margin-top: 24px;
    padding-bottom: 64px;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    list-style-type: none;
    margin: 0;
    
    @media (min-width: 1152px) {
      flex-wrap: nowrap;
    }
  }

  &__item {
    flex-basis: 50%;

    &:nth-child(1),
    &:nth-child(3) {
      border-right: 1px #c4c4c4 solid;
    }

    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 1px #c4c4c4 solid;
      padding-bottom: 5px;
    }

    &:hover {
      background-color: #c4c4c4;
    }

    @media (min-width: 1152px) {

      &:not(:last-child) {
        border-right: 1px #c4c4c4 solid;
      }
      
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: none;
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    padding-top: 30px;

    @media (min-width: 768px) {
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 40px 40px 40px 40px;
    }

    @media (min-width: 1152px) {
      padding: 0;
    }
  }

  &_link-top {
    padding-top: 20px;
    
    @media (min-width: 1152px) {
      padding: 0;
    }
  }

  &__img {
    width: 60px;
    height: 60px;
    padding-top: 10px;

    @media (min-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    height: 50px;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    color: #666666;
    flex-basis: 100%;

    @media (min-width: 768px) {
      flex-basis: 50%;
    }

    @media (min-width: 1152px) {
      margin-top: 20px;
      flex-basis: 100%;
    }
  }
}
