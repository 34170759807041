.page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1152px;

  @media (min-width: 1152px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: normal;
    margin: 0 auto;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1152px) {
      align-items: start;
    }
  }

  &__img {
    margin-top: 73px;
    width: 100%;
    max-width: 464px;

    @media (min-width: 768px) {
      margin-top: 100px;
      width: 60.5%;
      max-width: 534.96px;
      max-height: 272px;
    }

    @media (min-width: 1152px) {
      margin-top: 93px;
      margin-bottom: 129px;
    }
  }

  &__title {
    margin: 40px 10px 0 10px;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    text-transform: uppercase;
    color: #666666;

    @media (min-width: 1152px) {
      margin: 93px 0 0 106px;
      text-align: start;
      width: 300px;
    }
  }

  &__description {
    margin: 20px 10px 0 10px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #666666;
    opacity: 0.75;

    @media (min-width: 768px) {
      margin: 10px 75px 0 75px;
    }

    @media (min-width: 1152px) {
      text-align: start;
      margin: 20px 23px 0 106px;
      width: 380px;
      height: 56px;
    }
  }

  &__link {
    margin: 40px 0;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #666666;
    opacity: 0.75;

    @media (min-width: 768px) {
      margin-top: 5px;
    }

    @media (min-width: 1152px) {
      margin: 40px 0 0 106px;
    }
  }
}
