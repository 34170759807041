.header {
  height: 53px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  z-index: 10;

  &__index{
    position: absolute;
  }

  &_black {
    background: #000;
  }

  @media (min-width: 768px) {
    height: 80px;
  }

  @media (min-width: 1024px) {
    height: 53px;
  }

  &__wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    margin: 0 auto;

    @media (min-width: 1024px) {
      justify-content: space-between;
    }
  }

  &__burger {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__logo {
    display: block;
    width: 176px;

    @media (min-width: 768px) {
      width: 230px;
    }
  }

  &__subtitle {
    font-size: 8px;
    max-width: 100px;
    line-height: 10px;
    padding-left: 11px;
    border-left: 2px solid #D02D26;
    display: none;
    margin-right: 40px;

    @media (min-width: 1024px) {
      display: block;
    }
  }

  &__nav {
    @media (min-width: 1024px) {
      height: 100%;
      width: calc(100% - 389px);
    }
  }

  &__feedback{
    width: 170px;

    @media (min-width: 1024px) {
      max-width: 140px;
    }
  }

  &__button {
	width: 140px;
	height: 30px;
	font-size: 12px;
	line-height: 16px;
    padding: 7px;
    margin-left: 100px;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }

    @media (min-width: 1024px) {
      margin-left: 0;
    }
  }
}
