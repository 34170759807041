.external-link {
  display: block;
  position: relative;
  padding-left: 25px;
  color: #666;

  &:before {
    position: absolute;
    content: "";
    background: url(../images/arrow_red.svg) no-repeat;
    width: 20px;
    height: 20px;
    left: 0;
    top: 5px;
  }
}
