.room-page {
  padding-top: 83px;

  @media (min-width: 768px) {
    padding-top: 120px;
  }

  @media (min-width: 1025px) {
    padding-top: 120px;
  }

  .inner-nav {
    margin: 0 10px;

    @media (min-width: 768px) {
      margin: 0;
    }
  }

  &__top-nav {
    @media (min-width: 768px) {
      width: calc(100vw - 60px);
    }

    @media (min-width: 1025px) {
      width: 100%;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__view-wrapper {
    width: 100vw;
    margin: 0 -10px;

    @media (min-width: 768px) {
      margin: 0;
      width: calc(100% - 170px);
    }

    @media (min-width: 1025px) {
      width: calc(100% - 220px);
    }
  }

  &__info {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    margin: 6px auto;
    height: 16px;

    &::before {
      content: 'Нажмите на предмет который вас интересуют';
      position: absolute;
      left: 10px;
      top: 0;

      @media (min-width: 768px) {
        left: 50%;
        transform: translateX(-50%);
      }

      @media (min-width: 1025px) {
        content: 'Наведите курсор на предмет который вас интересуют';
      }
    }

    @media (min-width: 768px) {
      max-width: 100%;
      margin: 12px 0;
    }
  }

  &__view {
    position: relative;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: block;

    @media (min-width: 768px) {
      border-radius: 10px;
      margin: 0;
    }
  }

  &__view-image {
    height: auto;
    width: 100%;
    display: block;

    @media (min-width: 768px) {
      border-radius: 10px;
    }
  }

  &__view-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    transition: all .5s ease-in-out;
    background-size: contain;
    background-position: center;
    opacity: 0;
    z-index: 2;

    &_mask {
      background-color: rgba(0, 0, 0, 0.5);

      @media (min-width: 768px) {
        border-radius: 10px;
      }
    }

    &_carriage._entrance {
      background-image: url('../images/room-page/entrance/carriage.png');
    }

    &_videophone._entrance {
      background-image: url('../images/room-page/entrance/videophone.png');
    }

    &_handrails._entrance {
      background-image: url('../images/room-page/entrance/handrails.png');
    }

    &_helper._entrance {
      background-image: url('../images/room-page/entrance/helper.png');
    }

    &_hammock._room {
      background-image: url('../images/room-page/room/hammock.png');
    }

    &_cabinet._room {
      background-image: url('../images/room-page/room/cabinet.png');
    }

    &_learning._room {
      background-image: url('../images/room-page/room/learning.png');
    }

    &_sport-zone._room {
     background-image: url('../images/room-page/room/sport-zone.png');
    }


    &_bath._bathroom {
      background-image: url('../images/room-page/bathroom/bath.png');
    }

    &_toilet._bathroom {
      background-image: url('../images/room-page/bathroom/toilet.png');
    }

    &_accessories._bathroom {
      background-image: url('../images/room-page/bathroom/accessories.png');
    }

    &_helpers._bathroom {
      background-image: url('../images/room-page/bathroom/helpers.png');
    }

    &_bed._bedroom {
      background-image: url('../images/room-page/bedroom/bed.png');
    }

    &_cabinet._bedroom {
      background-image: url('../images/room-page/bedroom/cabinet.png');
    }

    &_smart-home._bedroom {
      background-image: url('../images/room-page/bedroom/smart-home.png');
    }

    &_sanitary._bedroom {
      background-image: url('../images/room-page/bedroom/sanitary.png');
    }

    &_food-zone._kitchen {
      background-image: url('../images/room-page/kitchen/food-zone.png');
    }

    &_dishes._kitchen {
      background-image: url('../images/room-page/kitchen/dishes.png');
    }

    &_furniture._kitchen {
      background-image: url('../images/room-page/kitchen/kitchen-gadgets.png');
    }

    &_plumbing._kitchen {
      background-image: url('../images/room-page/kitchen/plumbing.png');
    }

    &_kitchen-gadgets._kitchen {
      background-image: url('../images/room-page/kitchen/security-gadgets.png');
    }
  }

  &__view-item {
    position: absolute;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    /* Позиционирование объектов ЖИЛАЯ КОМНАТА */

    &_hammock._room {
      min-width: 7%;
      min-height: 58%;
      right: 22%;
      top: 13%;
      left: auto;
      z-index: 2;
    }

    &_cabinet._room {
      min-width: 7%;
      min-height: 43%;
      left: 29%;
      top: 32%;
      z-index: 1;
    }

    &_learning._room {
      min-width: 26%;
      min-height: 43%;
      left: 1%;
      bottom: 3%;
    }

    &_swedish-stairs._room {
      min-width: 9%;
      min-height: 62%;
      right: 13%;
      top: 26%;
      z-index: 1;
    }

    &_trainers._room {
      min-width: 35%;
      min-height: 29%;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    /* Позиционирование объектов ЖИЛАЯ КОМНАТА */


    /* Позиционирование объектов ПОДЪЕЗД */

    &_carriage._entrance {
      min-width: 17%;
      min-height: 66%;
      right: 48%;
      bottom: 4%;
      z-index: 2;
    }

    &_videophone._entrance {
      min-width: 10%;
      min-height: 11%;
      top: 35%;
      left: 4%;
    }

    &_handrails._entrance {
      z-index: 1;
      min-width: 48%;
      right: 0;
      bottom: 8%;
      min-height: 47%;
    }

    &_helper._entrance {
      z-index: 1;
      min-width: 2%;
      left: 25%;
      top: 11%;
      min-height: 30%;
      right: auto;
      bottom: auto;
    }

    /* Позиционирование объектов ПОДЪЕЗД */


    /* Позиционирование объектов ВАННАЯ */

    &_rug._bathroom {
      min-width: 16%;
      min-height: 10%;
      bottom: 8%;
      left: 27%;
    }

    &_brash._bathroom {
      min-width: 8%;
      min-height: 10px;
      top: 50%;
      left: 51%;
    }

    &_toilet._bathroom {
      right: 4%;
      min-width: 9%;
      min-height: 42%;
      bottom: 5%;
      z-index: 1;
    }

    &_shower._bathroom {
      min-width: 10%;
      min-height: 25%;
      right: 23%;
      top: 27%;
      z-index: 1;
    }

    &_handrail-1._bathroom {
      min-width: 20%;
      min-height: 19%;
      bottom: 6%;
      left: 0;
    }

    &_handrail-2._bathroom {
      min-width: 9%;
      min-height: 7%;
      top: 50%;
      left: 24%;
    }

    &_handrail-3._bathroom {
      min-width: 11%;
      min-height: 6%;
      top: 50%;
      left: 39%;
    }

    &_handrail-4._bathroom {
      min-width: 14%;
      right: 12%;
      min-height: 10%;
      bottom: 37%;
      z-index: 2;
    }

    &_walker._bathroom {
      min-width: 12%;
      min-height: 32%;
      bottom: 6%;
      right: 28%;
    }

    &_handrail-5._bathroom {
      min-width: 4%;
      min-height: 31%;
      right: 1%;
      top: 34%;
      z-index: 2;
    }

    &_washbasin._bathroom {
      min-width: 11%;
      min-height: 16%;
      bottom: 29%;
      left: 0;
    }

    &_bath._bathroom {
      width: 26%;
      height: 21%;
      bottom: 20%;
      left: 22%;
    }

    &_shower-chair._bathroom {
      min-width: 8%;
      min-height: 20%;
      bottom: 34%;
      right: 25%;
    }

    /* Позиционирование объектов ВАННАЯ */


    /* Позиционирование объектов СПАЛЬНЯ */
    &_bed._bedroom {
      min-width: 24%;
      min-height: 32%;
      left: 40%;
      bottom: 19%;
      z-index: 1;
    }

    &_tablet._bedroom {
      min-width: 12%;
      min-height: 11%;
      bottom: 27%;
      left: 40%;
      z-index: 2;
    }

    &_station._bedroom {
      min-width: 5%;
      min-height: 10%;
      right: 29%;
      bottom: 37%;
      z-index: 2;
    }

    &_cabinet._bedroom {
      min-width: 27%;
      min-height: 72%;
      bottom: 2%;
      left: 0;
    }

    &_toilet-chair._bedroom {
      min-width: 7%;
      min-height: 23%;
      bottom: 25%;
      left: 33%;
      z-index: 2;
    }

    &_table._bedroom {
      min-width: 23%;
      min-height: 34%;
      bottom: 5%;
      right: 0;
    }

    /* Позиционирование объектов СПАЛЬНЯ */


    /* Позиционирование объектов КУХНЯ */

    &_food-zone._kitchen {
      min-width: 11%;
      min-height: 17%;
      left: 28%;
      bottom: 30%;
    }

    &_dishes._kitchen {
      min-width: 5%;
      min-height: 13%;
      right: 24%;
      top: 28%;
      z-index: 2;
    }

    &_kitchen-gadgets._kitchen {
      min-width: 50%;
      min-height: 19%;
      right: 0;
      bottom: 26%;
      z-index: 1;
    }

    &_plumbing._kitchen {
      min-width: 6%;
      min-height: 16%;
      right: 15%;
      bottom: 45%;
      z-index: 2;
    }

    &_protective-grill._kitchen {
      min-width: 9%;
      min-height: 6%;
      left: 60%;
      bottom: 45%;
      z-index: 2;
    }

    &_protective-handle._kitchen {
      min-width: 3%;
      min-height: 4%;
      left: 66%;
      bottom: 35%;
      z-index: 2;
    }

    /* Позиционирование объектов КУХНЯ */
  }

  &__tooltip-wrap {
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    z-index: 20;
  }

  &__tooltip-list {
    list-style: none;
    padding: 13px 15px;
    margin: 0;
    background-color: rgba(255,255,255,.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 220px;
  }

  &__tooltip-item {
    line-height: 14px;
    margin-bottom: 9px;
    width: 100%;

    &:nth-last-child(2) {
      padding-bottom: 15px;
      border-bottom: 1px solid #DEDEDE;
      margin-bottom: 0;
    }

    &:last-child {
      padding-top: 10px;
      text-transform: uppercase;
      margin-bottom: 0;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 5px;
        right: 0;
        background-image: url('../images/arrow_red.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 27px;
        height: 27px;
        z-index: 1;
      }
    }
  }

  &__tooltip-link {
    text-decoration: none;
    color: #666;
    font-size: 14px;
    line-height: 14px;
    display: block;
    position: relative;
    z-index: 2;

    &:hover {
      opacity: .85;
    }
  }

  &__tooltip-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
    z-index: 10;
    cursor: pointer;
    background-image: url('../images/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: unset;
    border: 0;

    @media (min-width: 1025px) {
      display: none;
    }
  }

  &__gallery {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 20px;
    overflow-x: auto;

    @media (min-width: 768px) {
      margin: 5px 0;
      overflow-x: hidden;
      flex-wrap: wrap;
      max-width: 100%;
    }

    @media (min-width: 1025px) {
      margin: 12px 0 0;
    }
  }

  &__gallery-link {
    cursor: pointer;
    display: block;

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }

    @media (min-width: 768px) {
      width: calc(50% - 5px);
      margin: 5px 0;
      height: 143px;

      &:not(:last-child) {
        margin: 5px 0;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    @media (min-width: 1025px) {
      margin: 0;
      min-width: 165px;
      height: auto;
      max-width: calc(100%/4 - 12px);

      &:not(:last-child) {
        margin: 0;
      }
    }
  }

  &__gallery-img {
    object-fit: cover;
    display: block;
    height: 100%;
    border-radius: 10px;

    @media (min-width: 768px) {
      width: 100%;
    }
  }

  &__aside {

    @media (min-width: 768px) {
      margin-left: 10px;
      padding-top: 70px;
      width: 170px;
    }

    @media (min-width: 1025px) {
      margin-left: 20px;
      width: 220px;
    }
  }

  &__nav {
    padding: 0 0 20px;
    border-bottom: 1px solid #E5E5E5;
  }

  &__nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__nav-item {
    font-size: 16px;
    line-height: 16px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__nav-link {
    text-decoration: none;
    color: #666666;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    display: inline-block;
    transition: all .3s ease-in-out;

    &:hover {
      opacity: .85;
      text-decoration: underline;
    }
  }

  &__aside-link {
    color: #666666;
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
    display: inline-block;
  }
}
