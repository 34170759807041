.nav {
  font-size: 12px;

  &__list,
  &__sublist{
    padding: 0;
    margin: 0;
    list-style: none;
	z-index: 100;
  }

  &__item_active > &__link,
  &__subitem_active > &__sublink,
  &__subitem:hover,
  &__item:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  &__link {
    color: #fff;
    text-decoration: none;
    transition: all 0.2s ease-out;
    padding: 19px 17px 22px;
    line-height: 12px;
  }

  &__item-is-submenu {
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid #fff;
      right: 4px;
      top: 23px;
      display: none;
      transition: transform .3s;

      @media (min-width: 1024px) {
        display: block;
      }
    }

    &:hover {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  &__button {
    display: block;
    width: 140px;
    height: 30px;
    font-size: 12px;
    line-height: 28px;
    margin: 10px auto 0;

    @media (min-width: 768px) {
      display: none;
    }
  }

  /**
   * Mobile & Tablet
   */
  @media (max-width: 1023px) {
    position: fixed;
    width: 100vw;
    height: auto;
    top: 42px;
    left: 0;
    background: #000;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    overflow-y: auto;
    z-index: 999;

    &_active {
      transform: translateX(0%);
    }

    &__sublist {
      display: none;
    }

    &__list {
      display: flex;
      height: 100%;
      flex-direction: column;
      padding: 2rem 0;
    }

    &__item {
      width: 100%;
    }

    &__link {
      display: block;
      padding: 10px 0;
      text-align: center;

      @media (min-width: 1024px) {
        padding: 1rem 0;
      }
    }
  }

  /**
   * Desktop
   */
  @media (min-width: 1024px) {
    height: 100%;

    &__list {
      display: flex;
      height: 100%;
    }

    &__sublist {
      position: absolute;
      top: 53px;
      width: 190px;
      background: rgba(0, 0, 0, 0.5);
      right: 0;
      display: none;
      padding-bottom: 10px;

      &_active {
        display: block;
      }
    }

    &__subitem {
      padding: 0 15px;
    }

    &__sublink {
      color: #fff;
      display: block;
      text-align: right;
      text-decoration: none;
      text-transform: none;
      font-size: 14px;
      line-height: 24px;
      padding: 5px 0;
    }

    &__item {
      display: flex;
      align-items: center;
      position: relative;

      &:hover > .nav__sublist {
        display: block;
      }
    }

    &__item_active > .nav__sublist{
      display: block;
    }

    &__item_active {
      border-color: #4581E9;
    }
  }
}
