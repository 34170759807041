.room-card {
  flex-basis: 30%;
  flex-grow: 1;

  @media (min-width: 768px) {
    &:nth-child(3) {
      margin-right: 10px;
    }

    &:nth-child(4) {
      margin-right: 10px;
    }
  }

  @media (min-width: 1152px) {
    &:nth-child(3) {
      margin-right: 20px;
    }

    &:nth-child(4) {
      margin-right: 20px;
    }
  }

  &__title {
    margin: 0;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    color: #666666;
    margin-bottom: 5px;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  &__img {
    img {
      width: 100%;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 20px;

    & a {
      color: #666666;
    }
  }

  &__top {
    flex-basis: 40%;
    padding: 0;

    @media (min-width: 768px) {
      &:first-child {
        margin-right: 15px;
      }
    }

    @media (min-width: 1152px) {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
