.footer-contacts {
  flex-basis: 50%;

  @media (min-width: 768px) {
    flex-basis: auto;
    padding-top: 5px;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    & li {
      height: 20px;
      display: flex;
      justify-content: flex-end;

      @media (min-width: 768px) {
        justify-content: center
      }
    }
  }

  &__tel {
    text-decoration: none;
    font-size: 16px;
    line-height: 21px;

    text-transform: uppercase;
    color: white;
  }

  &__email {
    text-decoration: none;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    color: white;
    text-decoration: underline;
  }
}
