.footer {
  background: #666666;
  flex-shrink: 0;

  &__container {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 auto;

    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__col-logo {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(196, 196, 196, 0.25);
    margin-top: 10px;
    text-align: center;

    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 20px 0 28px 0;
      border: none;
      text-align: left;
    }
  }

  &__logo {
    width: 182.55px;
    height: 35px;
  }

  &__logo-address {
    display: none;

    @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
    }
  }

  &__address-img {
    margin-right: 10px;
    padding-top: 3px;
    margin-left: 15px;
  }

  &__address {
    width: 148px;
    font-size: 14px;
    line-height: 15px;

    @media (min-width: 768px) {
      width: 170px;
      font-size: 16px;
      line-height: 21px;
      text-align: left;
    }
  }

  &__col-contacts {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(196, 196, 196, 0.25);
    margin-top: 10px;
    align-items: center;

    @media (min-width: 768px) {
      border: none;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      flex-wrap: nowrap;
    }
  }

  &__contacts-title {
    flex-basis: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 7px;

    @media (min-width: 768px) {
      flex-basis: auto;
      margin-bottom: 0;
    }
  }

  &__contacts-address {
    flex-basis: 50%;

    @media (min-width: 768px) {
      display: none;
    }
  }

  &__contacts {
  }

  &__col-info {
    display: flex;
    padding-top: 10px;
    align-items: center;

    @media (min-width: 768px) {
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
    }
  }

  &__privacy {
    flex-basis: 50%;
    font-size: 12px;
    line-height: 15px;
    text-decoration: none;
    text-decoration-line: underline;

    @media (min-width: 768px) {
      flex-basis: auto;
    }

    &-link {
      max-width: 144px;

      @media (min-width: 768px) {
        max-width: 190px;
      }

      & a {
        color: white;
      }
    }
  }

  &__col-developer {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    @media (min-width: 768px) {
      flex-basis: auto;
      align-items: flex-start;
    }
  }

  &__developer {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 6px;
  }

  &__col-copyright {
    margin-top: 27px;
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    background: #000000;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    opacity: 0.75;

    @media (min-width: 768px) {
      flex-basis: 100%;
      margin-top: 0;
    }
  }

  .developer-copyright {
    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
