.container {
  max-width: 1152px;
  padding: 0 10px;
  margin: 0 auto;

  &_full-height {
    height: 100%;
  }

  @media (min-width: 768px) {
    padding: 0 30px;
  }
}
