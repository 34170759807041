.inner-nav {
  position: relative;
  padding: 0;
  overflow-x: scroll;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &__list {
    display: flex;
    padding: 0;
    margin: 0;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
  }

  &__item {
    list-style-type: none;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    padding: 2px 7.5px;
    text-align: center;
    flex: 1 1 auto;
    opacity: 0.5;
    border-bottom: 1px solid #E5E5E5;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  &__link {
    text-decoration: none;
    color: #666666;
    white-space: nowrap;
    transition: all .3s ease-in-out;

    &:hover {
      color: #111;
    }
  }

  &__item_active {
    border-bottom: 3px solid #d02d26;
    opacity: 1;
    z-index: 10;

    &__link {
      font-weight: bold;
    }
  }
}
