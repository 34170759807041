/**
 * За пределами блока typography удобно использовать классы заголовков.
 * Отступы для таких классов обнулены, т.к. обычно задаются в контексте интерфейса.
 */
%h1, %h2, %h3, %h4, %h5, %h6 {
  color: inherit;
  line-height: 1.2;
  font-weight: 500;
}

%h1 { font-size: 1.5rem; }

%h2 { font-size: 1.25rem; }

%h3 { font-size: 1rem; }

%h4 { font-size: 1rem; }

%h5 { font-size: 1rem; }

%h6 { font-size: 1rem; }

/**
 * Блок typography используется там, где необходимо отображать оформленный текст.
 * Обычно это содержимое страниц, заполненное в WYSIWYG редакторе.
 */
.typography {
  font-size: inherit;
  line-height: inherit;

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    text-transform: uppercase;
  }

  h1 {
    @extend %h1;
    font-weight: 700;
    margin-bottom: 20px;
  }

  h2 {
    @extend %h2;
    font-weight: 700;
  }

  h3 { @extend %h3; }

  h4 { @extend %h4; }

  h5 { @extend %h5; }

  h6 { @extend %h6; }

  p {
    margin: 0;
    font-size: 1rem;
    line-height: 20px;

    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  hr {
    height: 0;
    margin: 1rem 0;
    border-left: 0;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #ffffff;
  }

  i, em {
    font-style: italic;
  }

  b, strong {
    font-weight: bolder;
  }

  blockquote {
    font-size: 1.25rem;

    padding: .25rem 0 .25rem 1rem;
    margin-bottom: 1rem;

    border-left: 2px solid gray;
  }

  ul, ol {
    padding-left: 40px;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  ul {
    list-style-type: disc;

    ul {
      list-style-type: circle;
    }
  }

  ol {
    list-style-type: decimal;
  }

  li {
    display: list-item;
  }

  mark {
    background-color: gray;
  }

  small {
    font-size: 80%;
  }

  img,
  picture {
    max-width: 100%;
    height: auto;
  }

  table {
    max-width: 100%;
    width: 100%;

    margin-bottom: 1rem;

    border: 1px solid gray;
    background-color: transparent;

    th, td {
      padding: .75rem;
      text-align: inherit;
      border: 1px solid gray;
    }

    thead th {
      font-weight: bold;
      border-bottom: 2px solid gray;
    }
  }
}
