.equipment-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  &__item {
    margin-top: 10px;

    @media (min-width: 768px) {
      margin-top: 20px;
    }

    @media (min-width: 1152px) {
      margin: 10px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
	&__link {
		text-decoration: none;
		color: #666;

		&:hover .equipment-card{
			background: #eceaea
		}
	}

}
