*,
*:before,
*:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #666;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }
}

main {
  flex: 1 0 auto;
}
a {
  color: #4581e9;
  font-weight: 500;
  &:hover {
    text-decoration: none;
  }
}

.hidden {
  display: none !important;
}
