.question {
    background: #f2f2f2;

  &__wrapper {
    padding: 30px;
    display: flex;
    font-size: 16px;
    line-height: 20px;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__block {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    align-items: center;
    border-bottom: 1px solid #C4C4C4;

    @media (min-width: 768px) {
      align-items: start;
      border-bottom: none;
      min-width: 300px;
    }
  }

  &__block-text {
    font-weight: 700;

    &:first-child {
      margin-top: 10px;
    }
  }

  &__button {
    padding: 7px 25px;
    margin-top: 20px;
    display: inline-block;
  }

  &__title {
     margin: 10px 0;
  }

  &__text {
    border-left: none;
    padding-left: 0;
    color: #666666;
    text-align: center;
    max-width: 90%;
    margin: 0 auto;

    @media (min-width: 768px) {
      text-align: left;
      border-left: 1px solid #C4C4C4;
      padding-left: 20px;
    }

    @media (min-width: 1152px) {
      padding-left: 40px;
    }
  }
}
