.special {
  padding-top: 20px;
  padding-bottom: 25px;
  position: relative;

  &__title{
    margin-top: 0;
    border-top: none;
    text-transform: uppercase;
    font-size: 18px;

    @media (min-width: 768px) {
      position: absolute;
      top: 5px;
      background: white;
      z-index: 2;
      padding-right: 25px;
    }

    @media (min-width: 1152px) {
      position: unset;
      padding-top: 20px;
      border-top: 1px solid #E5E5E5;
    }
  }

  &__list {
    position: relative;
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-basis: 25%;
    padding-top: 5px;

    @media (min-width: 768px) {
      padding-top: 40px;
      align-items: center;
      border-top: 1px solid #E5E5E5;
    }

    &:after {
      position: absolute;
      content: '';
      width: calc(50% - 70px);
      height: 1px;
      background: #E5E5E5;
      bottom: 0;
      right: 0;

      @media (min-width: 768px) {
        width: calc(50% - 100px);
      }
    }

    &:before {
      position: absolute;
      content: '';
      width: calc(50% - 60px);
      height: 1px;
      background: #E5E5E5;
      bottom: 0;
      left: 0;

      @media (min-width: 768px) {
        width: calc(50% - 100px);
      }
    }

    @media (min-width: 1152px) {
      flex-wrap: nowrap;
      padding-bottom: 25px;
      padding-top: 0;
      border-top: none;
      border-bottom: 1px solid #E5E5E5;

      &:after,
      &:before{
        display: none;
      }
    }
  }

  &__item {
    display: flex;
    flex-basis: 50%;
    margin-bottom: 30px;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (min-width: 1152px) {
      flex-basis: unset;
        margin-bottom: 0;
    }
  }

  &__img {
    width: 35px;
    height: 35px;

    @media (min-width: 768px) {
      width: 55px;
      height: 55px;
    }

    @media (min-width: 1152px) {
      width: 35px;
      height: 35px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    max-width: 130px;
    margin-top: 10px;

    @media (min-width: 768px) {
      max-width: 270px;
      margin-left: 10px;
      margin-bottom: 0;
    }

    @media (min-width: 1152px) {
      max-width: 163px;
    }
  }

  &__text {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 18px;
  }

  &__number{
    font-size: 16px;
    opacity: 0.75;
  }

  &__button {
    padding: 7px 27px;
    line-height: 16px;
    height: 30px;
    font-size: 12px;
    position: absolute;
    bottom: -15px;
    left: calc(50% - 50px);

    @media (min-width: 768px) {
      height: 45px;
      padding: 7px 57px;
      line-height: 30px;
      left: calc(50% - 88px);
      bottom: -22px;
      font-size: 16px;
    }

    @media (min-width: 1152px) {
      position: unset;
      height: 30px;
      padding: 7px 35px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
