.inner-page {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &__content {
    width: 100%;
    max-width: 780px;
    margin-bottom: 40px;

    @media (min-width: 768px) {
      margin-right: 20px;
    }
  }

  &__sidebar {
    width: 300px;
  }
}
