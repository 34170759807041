.products-list {
  margin: 20px 0 32px;

  &__category {
    margin-bottom: 20px;

    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
