.rooms-list {
  padding-bottom: 20px;

  
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

