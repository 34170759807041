.filters-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 22px;
  left: 50%;
  transform: translateX(-50%);

  &__button {
    width: 140px;

    &_apply {
      font-size: 12px;
      line-height: 16px;
      padding: 7px 34px;
      margin-bottom: 16px;
    }

    &_clear {
      background-color: white;
      border: 1px solid #bfbfbf;
      color: #b3b3b3;
      padding: 8px 0 8px 14px;
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 13px;
      text-transform: initial;
      transition: all .3 ease-in-out;

      svg {
        margin-right: 18px;
      }
    }
  }

  @media (min-width: 1025px) {
    display: none;
  }
}
