.textarea {
  border: none;
  border-bottom: 1px solid #E5E5E5;
  padding: 5px 20px;
  outline: none;
  width: 100%;
  font-size: 16px;
  text-align: center;
  resize: none;

  @media (min-width: 768px) {
    text-align: left;
  }

  &:focus-visible {
    border: none;
    border-bottom: 1px solid #666;
  }
}
