.product-card {
  position: relative;
  display: block;
  width: 100%;
  padding: 16px 56px 16px 16px;
  color: #666;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  border: 2px solid #e1eded;
  border-radius: 6px;

  &:hover, &:focus {
    border-color: #b6d4d4;
  }

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 22px;

    &:hover, &:focus {
      padding-left: 32px;
    }
  }

  &__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    fill: #e1eded;
  }

  &:hover &__icon,
  &:focus &__icon {
    fill: #b6d4d4;
  }
}
