.developer-copyright {
  display: flex;
  align-items: center;

  & p {
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    margin-left: 5px;
  }
}
