.sidebar {
  background: #F8F8F8;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-left: 0;

  @media (min-width: 768px) {
    margin-left: 20px;
    margin-top: 91px;
  }


  &__title {
    font-size: 18px;
  }

  &__wrapper {
    list-style: none;
    padding-left: 0;
  }

  &__link {
    color: #666666;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: #80B4B5;
      text-decoration: underline;
    }
  }
}
